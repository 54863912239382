


























































































import { Component } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IDocument } from "@/interfaces/IDocument";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import { IStudent } from "@/interfaces/IStudent";
import Table from "@/components/Table.vue";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import { getFile } from "@/utils/File";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import { IBasicStudent } from "@/interfaces/IBasicStudent";
import { IBasicStudentWithBirthDate } from "@/interfaces/IBasicStudentWithBirthDate";
import { formatStudentName } from "@/utils/NameUtil";

const DocumentModule = namespace("document");
const StudentModule = namespace("student");

@Component({
  components: {
    SaveButton,
    AbortButton,
    PdfView,
    Table,
    EditButton,
    RemoveButton,
    FscSimpleCard,
    FscPageHeader,
  },
})
export default class StudentUploadDocuments extends mixins(TabMixin) {
  public name = "StudentUploadDocuments";

  public fields = [
    {
      key: "pageNumbers",
      label: this.$t("general.page"),
      sortable: false,
    },
    {
      key: "name",
      label: this.$t("user.last_name"),
      sortable: false,
    },
    {
      key: "birthDate",
      label: this.$t("user.birth_date"),
      sortable: false,
    },
    {
      key: "title",
      label: this.$t("general.title"),
      sortable: false,
    },
    {
      key: "actions",
      label: "",
      sortable: false,
    },
  ];

  @DocumentModule.Getter("getDocumentsInProcessing")
  public items!: Array<IDocument>;

  @DocumentModule.Action("documentsInProcessing")
  public documentsInProcessing!: (options: ICrudOptions) => Promise<void>;

  @DocumentModule.Action("documentsInProcessingDelete")
  public documentsInProcessingDelete!: (options: ICrudOptions) => Promise<void>;

  @DocumentModule.Getter("getDocumentsInProcessingDeleteSuccess")
  public getDocumentsInProcessingDeleteSuccess!: boolean;

  @DocumentModule.Action("attachDocumentsInProcessing")
  public attachDocumentsInProcessing!: (options: ICrudOptions) => Promise<void>;

  @DocumentModule.Getter("getAttachDocumentsInProcessingLoading")
  public attachDocumentsInProcessingLoading!: boolean;

  @DocumentModule.Getter("getAttachDocumentsInProcessingSuccess")
  public getAttachDocumentsInProcessingSuccess!: boolean;

  @StudentModule.Getter("getDataList")
  public students!: Array<IStudent>;

  @StudentModule.Action("list")
  public studentsList!: (filter: Record<any, any>) => Promise<void>;

  protected pdfFile = "";

  public onRowClicked(ctx: any) {
    if (!ctx.drivingSchoolDocumentId) return;
    getFile(
      {
        method: "GET",
        url: `driving-school-documents/${ctx.drivingSchoolDocumentId}/download`,
      },
      false
    ).then((blob: any) => {
      this.pdfFile = blob;
    });
  }

  public fetchDocumentsInProcessing(): void {
    this.documentsInProcessing({
      resource: "documents/documents-in-processing",
    });
  }

  public mounted(): void {
    this.studentsList({
      offset: 0,
      limit: 999999,
    });

    if (!this.$route.params.fetch) {
      this.fetchDocumentsInProcessing();
    }
  }

  public activated(): void {
    if (this.$route.params.fetch) {
      this.fetchDocumentsInProcessing();
    }
  }

  protected studentsLabel(student: any): string {
    return student ? formatStudentName(student.firstName, student.lastName) : "";
  }

  protected onAbortUploadDocument(): void {
    this.closeCurrentTab();
  }

  protected async onSubmitUploadDocument(): Promise<void> {
    if (!this.items.length) return;

    await this.attachDocumentsInProcessing({
      resource: "documents/attach-documents-in-processing",
      data: this.items,
    });

    if (this.getAttachDocumentsInProcessingSuccess) {
      this.fetchDocumentsInProcessing();
    }
  }

  protected async removeDocument(item: IDocument): Promise<void> {
    if (!item.id) return;
    await this.documentsInProcessingDelete({
      resource: `documents/documents-in-processing/${item.id}`,
    });

    if (this.getDocumentsInProcessingDeleteSuccess) {
      this.fetchDocumentsInProcessing();
    }
  }

  protected editDocument(item: IDocument): void {
    //
  }

  protected reformatStudentName(item: IBasicStudent): string {
    return item ? formatStudentName(item.firstName, item.lastName) : "";
  }

  protected birthDate(student: IBasicStudentWithBirthDate): string {
    return student ? this.$options.filters?.formatDate(student.birthDate) : "";
  }
}
